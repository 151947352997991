<template>
  <v-form
    id="addCompanyForm"
    ref="addCompanyForm"
    v-model="valid"
    lazy-validation
    autocomplete="off"
    @submit.prevent=""
  >
    <v-container class="py-0">
      <v-row class="mb-5">
        <v-col cols="12">
          <h2 class="page-title mb-1">{{ $t(`settings.title.${mode}`) }}</h2>
          <span class="page-step">
            {{ $t(`settings.company.step_${step}`, { step: step }) }}
          </span>
        </v-col>
        <!-- <v-col v-if="pageMode === 'edit'" class="pt-0">
          <span>{{ orgName }}</span>
        </v-col>-->
      </v-row>
      <template v-if="step === 1">
        <v-row>
          <v-col
            cols="12"
            sm="6"
            md="5"
            lg="4"
            xl="3"
            class="d-flex align-center"
          >
            <v-text-field
              v-model="modelCompany.okpo"
              id="clientOkpo"
              :rules="rules.okpo"
              :label="$i18n.t('label.okpo')"
              :disabled="isLoading"
              maxlength="10"
              color="privat"
              autofocus
              required
              outlined
              dense
              @keyup.enter="getInfoCompanyByOkpo"
            ></v-text-field>
          </v-col>
        </v-row>
      </template>
      <template v-if="step === 2">
        <v-row v-if="showAlert.show">
          <v-col>
            <v-alert
              color="blue-grey lighten-5 mb-5"
              :class="showAlert.class"
              :icon="showAlert.icon"
              prominent
            >
              {{ showAlert.msg }}.
            </v-alert>
          </v-col>
        </v-row>
        <v-row>
          <BaseSelectAddress
            v-if="enterManually"
            :validate="needValidate"
            :loading="loading"
            @getAddrId="getAddressId"
            @clearAddrId="clearAddrId"
            @error="errorFromChild"
          />
          <v-col cols="12" sm="8" md="7" lg="6" v-if="!enterManually">
            <v-badge :color="markAddress" dot overlap>
              <v-text-field
                id="mainOffice"
                v-model="modelCompany.addressUaText"
                :label="$t('label.mainOffice')"
                color="privat"
                disabled
                outlined
                dense
              ></v-text-field>
            </v-badge>
          </v-col>
          <v-col
            cols="12"
            sm="4"
            md="3"
            lg="2"
            class="pb-2 pb-sm-0 mt-n2 mt-sm-0 mb-2 mb-sm-0"
          >
            <v-btn
              id="enterManually"
              class="mr-sm-4 mb-4 mb-sm-0"
              elevation="1"
              color="blue-grey lighten-5"
              :disabled="loading"
              @click="addressManual"
            >
              <span>
                {{
                  !enterManually
                    ? $t("action.enterManually")
                    : $t("action.addrFromEkb")
                }}
              </span>
            </v-btn>
          </v-col>
        </v-row>
        <v-divider class="mb-6"></v-divider>
        <v-row>
          <v-col cols="12" sm="6" md="5" lg="4" xl="3">
            <v-text-field
              id="nameOrg"
              v-model="modelCompany.name"
              :label="$t('label.name')"
              :rules="rules.name"
              :disabled="loading"
              maxlength="100"
              minlength="3"
              color="privat"
              required
              outlined
              dense
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6" md="5" lg="4" xl="3">
            <v-text-field
              id="translateOrg"
              v-model="modelCompany.translatedName"
              :label="$t('label.nameLatin')"
              :rules="rules.translatedName"
              :disabled="loading"
              maxlength="40"
              minlength="3"
              color="privat"
              required
              outlined
              dense
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="6" md="5" lg="4" xl="3">
            <BaseSelectSupport
              :supports="supports"
              :label="`supportLdap`"
              :loading="loading"
              :validate="needValidate"
              @setSupport="setSupport"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="6" lg="4" xl="3">
            <v-switch
              v-model="modelCompany.otpRequired"
              id="otpRequired"
              class="mt-0"
              color="privat"
              :disabled="loading"
            >
              <template v-slot:label>
                <label for="otpRequired" class="ml-3">
                  {{ $t("label.otpRequired") }}
                </label>
              </template>
            </v-switch>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <p class="subtitle-1 font-weight-medium mb-0">
              {{ $t("settings.company.availableCategories") }}
            </p>
          </v-col>
        </v-row>
        <v-row
          v-for="detail in leasingObjects"
          :key="detail.id"
          class="pt-2 pb-3"
        >
          <v-col cols="12" lg="4" xl="6">
            <v-checkbox
              v-model="detail.active"
              class="detail-checkbox"
              :label="detail.nameDesc"
              :disabled="loading"
              hide-details="true"
              color="privat"
              @change="detail.matrixId = null"
            ></v-checkbox>
          </v-col>
          <v-col
            cols="12"
            lg="8"
            xl="6"
            v-if="detail.active"
            class="d-flex justify-start align-center flex-wrap"
            :class="{ 'mt-1': vwLess960px }"
          >
            <v-text-field
              v-model="detail.matrixId"
              class="detail-matrix"
              :label="vwLess960px ? $t('label.matrix_md') : $t('label.matrix')"
              :rules="rules.matrix"
              :required="detail.active"
              :disabled="loading"
              maxlength="1"
              hide-details="true"
              outlined
              dense
            >
              {{ detail.matrixId }}
            </v-text-field>
            <v-text-field
              v-model="detail.matrixIdComp"
              class="detail-matrix ml-2"
              :label="
                vwLess960px ? $t('label.matrixComp_md') : $t('label.matrixComp')
              "
              :rules="rules.matrix"
              :required="detail.active"
              :disabled="loading"
              maxlength="1"
              hide-details="true"
              outlined
              dense
            >
              {{ detail.matrixIdComp }}
            </v-text-field>
            <v-text-field
              v-model="detail.matrixIdComp579"
              class="detail-matrix ml-2"
              :label="
                vwLess960px
                  ? $t('label.matrixComp579_md')
                  : $t('label.matrixComp579')
              "
              :rules="rules.matrix"
              :required="detail.active"
              :disabled="loading"
              maxlength="1"
              hide-details="true"
              outlined
              dense
            >
              {{ detail.matrixIdComp579 }}
            </v-text-field>
          </v-col>
        </v-row>
        <v-divider class="mt-2 mb-6"></v-divider>
        <v-row>
          <v-col cols="12" md="10" lg="8">
            <v-textarea
              v-model="modelCompany.comment"
              :label="
                modelCompany.comment
                  ? $t('label.comment')
                  : `${$t('label.comment')} ${$t('label.notRequired')}`
              "
              hide-details="true"
              rows="2"
              outlined
              dense
            ></v-textarea>
          </v-col>
        </v-row>
      </template>

      <v-row>
        <v-col
          cols="12"
          sm="6"
          md="5"
          lg="4"
          xl="3"
          class="d-flex justify-space-between flex-column flex-sm-row my-6"
        >
          <v-btn
            id="cancel"
            class="mr-sm-4 mb-4 mb-sm-0"
            elevation="1"
            color="blue-grey lighten-5"
            :disabled="loading"
            @click="
              pageMode === 'add' && step === 2 ? (step = 1) : $router.go(-1)
            "
          >
            {{ textBtn }}
          </v-btn>
          <v-btn
            id="continue"
            elevation="1"
            color="privat"
            :loading="loading"
            :disabled="disabledBtn"
            @click="step === 1 ? getInfoCompanyByOkpo() : sendCompany()"
          >
            <span class="white--text">
              {{ step === 1 ? $t("action.continue") : $t("action.add") }}
            </span>
          </v-btn>
        </v-col>
      </v-row>
      <base-snackbar :props="snack" :show="show" @close="show = false" />
    </v-container>
  </v-form>
</template>

<script>
import { mapGetters } from "vuex";
import {
  interactionDataAddress,
  searchCompanyById,
  searchCompanyByOkpo
} from "@/services/settings.services";
import errorHandler from "@/utils/errorHandler";
import showSnack from "@/mixins/showSnack.mixins";
import BaseSelectAddress from "@/components/BaseSelectAddress";
import BaseSelectSupport from "@/components/BaseSelectSupport";
import CONST from "@/utils/constants";
import createMockData from "@/mock/mockNewData";

export default {
  name: "SettingsCompany",
  mixins: [showSnack],
  props: {
    options: Object,
    company: Object,
    loading: Boolean
  },
  data() {
    return {
      valid: true,
      isLoading: false,
      mode: "company/add",
      step: 1,
      modelCompany: {
        okpo: null, // "39104926"
        addressId: null,
        addressUaText: null,
        markAddress: "transparent",
        name: null,
        translatedName: null,
        supportLdap: null,
        otpRequired: false,
        comment: null
      },
      showAlert: {
        show: false,
        icon: "mdi-bank",
        class: "",
        msg: ""
      },
      enterManually: false,
      addressIdFromEkb: false,
      supports: [],
      needValidate: false,
      leasingObjects: []
    };
  },
  components: {
    BaseSelectAddress,
    BaseSelectSupport
  },
  computed: {
    ...mapGetters(["getAllOrg", "getPointOfEntry", "devEnv"]),
    textBtn() {
      let text = this.$i18n.t("action.cancel");

      if (this.pageMode === "edit" && this.step === 2) {
        text = this.$i18n.t("action.cancel");
      }
      if (this.pageMode !== "edit" && this.step === 2) {
        text = this.$i18n.t("action.back");
      }

      return text;
    },
    pageMode() {
      return this.$route.params.id;
    },
    orgName() {
      return this.company?.name || this.selectedCompany?.name;
    },
    getOrgId() {
      return this.$route.query.orgId;
    },
    selectedCompany() {
      return this.options.companies.find(el => el.id === +this.getOrgId);
    },
    rules() {
      return {
        okpo: [
          v => !!v || this.$i18n.t("rules.required"),
          v => CONST.REG_EXP_OPKO.test(v) || this.$i18n.t("rules.okpo.correct")
        ],
        name: [
          v => !!v || this.$i18n.t("rules.required"),
          v => CONST.MIN_MAX_NAME.test(v) || this.$i18n.t("rules.MIN_MAX_NAME")
        ],
        translatedName: [
          v => !!v || this.$i18n.t("rules.required"),
          v =>
            CONST.MIN_MAX_TRANSLATE_NAME.test(v) ||
            this.$i18n.t("rules.MIN_MAX_TRANSLATE_NAME")
        ],
        supportLdap: [v => !!v || this.$i18n.t("rules.required")],
        matrix: [
          v => !!v || this.$i18n.t("rules.required"),
          v => /[1-9]/.test(v) || this.$i18n.t("rules.isNotNumber")
        ]
      };
    },
    markAddress() {
      return this.modelCompany.markAddress.toLowerCase() || "transparent";
    },
    disabledBtn() {
      return this.showAlert.show || this.loading;
    },
    vwLess960px() {
      return (
        this.$vuetify.breakpoint.xs ||
        this.$vuetify.breakpoint.sm ||
        this.$vuetify.breakpoint.sm
      );
    }
  },
  methods: {
    addressManual() {
      this.enterManually = !this.enterManually;
      if (!this.enterManually) {
        this.showAlert.show = false;
        this.modelCompany.addressId = this.addressIdFromEkb;
      } else {
        this.modelCompany.addressId = null;
      }
    },
    initAlert(data) {
      let addrType = data.foundAddress.addrType;

      switch (addrType) {
        case "ATO":
          this.showAlertBadAddress();
          break;
        case "ConflictLine":
          this.showAlertBadAddress();
          break;
        case "Crimea":
          this.showAlertBadAddress();
          break;
      }
    },
    showAlertBadAddress() {
      this.showAlert.class = "ERROR";
      this.showAlert.msg = this.$i18n.t("settings.alert.badAddress");
      this.showAlert.icon = "mdi-alert-circle";
      this.showAlert.show = true;
    },
    getInfoCompanyByOkpo() {
      if (this.devEnv) {
        const data = createMockData("newOrg");

        this.setDefaultParamsCompany(data);
        this.initAlert(data);
        this.step = 2;
        // mock
        return;
      }

      if (this.$refs.addCompanyForm.validate()) {
        this.isLoading = true;
        searchCompanyByOkpo(this.modelCompany.okpo)
          .then(res => {
            let data = res.data;

            if (data) {
              this.setDefaultParamsCompany(data);
              this.initAlert(data);
              this.step = 2;
            } else {
              let errorCode = errorHandler({}, "getData");

              this.showSnack("error", [this.$i18n.t(`error.${errorCode}`)]);
              console.log("then getInfoCompanyByOkpo");
            }
          })
          .catch(err => {
            let errorCode = errorHandler(err, "getData");

            this.showSnack("error", [this.$i18n.t(`error.${errorCode}`)]);
            console.log("catch getInfoCompanyByOkpo");
          })
          .finally(() => {
            this.isLoading = false;
          });
      }
    },
    setDefaultParamsCompany(data) {
      let foundAddress = data.foundAddress;

      this.modelCompany = Object.assign(this.modelCompany, data);
      this.leasingObjects = data.objectDetailViewList.map(el => ({
        ...el
      }));
      if (!foundAddress.addressUaText) {
        this.addressManual();
      } else {
        this.addressIdFromEkb = foundAddress.addressId;
        this.modelCompany.addressId = foundAddress.addressId;
        this.modelCompany.addressUaText = foundAddress.addressUaText;
        this.modelCompany.markAddress = foundAddress.color;
      }
      if (data.managerLdap && data.managerFio) {
        this.createSupports(data);
      }
    },
    sendCompany() {
      let isSupport = this.modelCompany.supportLdap;
      let isAddrId = this.modelCompany.addressId;
      let lengthLeasingObj = 0;
      let isLeasingObj = this.leasingObjects.filter(el => {
        el.active ? lengthLeasingObj++ : false;
        if (el.active && el.matrixId) {
          return el;
        }
      });

      this.needValidate = !this.needValidate;
      if (
        this.$refs.addCompanyForm.validate() &&
        isAddrId &&
        isSupport &&
        !lengthLeasingObj
      ) {
        this.showSnack("error", [this.$i18n.t(`error.ORG_EMPTY_OBJ_TYPES`)]);
        return;
      }

      if (
        this.$refs.addCompanyForm.validate() &&
        isSupport &&
        isAddrId &&
        lengthLeasingObj &&
        lengthLeasingObj === isLeasingObj?.length
      ) {
        this.modelCompany.leasingObjects = isLeasingObj;
        this.modelCompany.managerLdap = null;
        this.modelCompany.managerFio = null;
        this.modelCompany.markAddress = null;
        this.modelCompany.foundAddress = null;
        this.modelCompany.objectDetailViewList = null;

        this.pageMode !== "edit"
          ? this.$emit("event", ["addCompany", this.modelCompany])
          : this.$emit("event", ["updateCompany", this.modelCompany]);
      }
    },
    createUrlAddress(modelAd) {
      let corpus = modelAd.corpus ? `&corpus=${modelAd.corpus}` : "";
      let flat = modelAd.flat ? `&flat=${modelAd.flat}` : "";
      let idStreet = modelAd.street.code;
      let building = modelAd.building.desc;

      return `esa/addr/updateWCheck?id=${idStreet}&house=${building}${corpus}${flat}`;
    },
    getAddressId(modelAd) {
      let url = this.createUrlAddress(modelAd);

      this.isLoading = true;
      interactionDataAddress(url, "POST")
        .then(res => {
          let data = res.data;
          console.log("getAddressId", data);

          this.modelCompany.addressId = data;
          this.showAlert.show = false;
        })
        .catch(err => {
          let errorCode = errorHandler(err, "other");

          if (errorCode === "ADDR_RESTRICTION") {
            this.showAlertBadAddress();
          } else {
            this.showAlert.show = false;
            this.showSnack("error", [this.$i18n.t(`error.${errorCode}`)]);
          }
          console.log("catch getAddressId");
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    clearAddrId() {
      this.modelCompany.addressId = null;
    },
    errorFromChild(error) {
      this.showSnack("error", error);
    },
    setSupport(val) {
      this.modelCompany.supportLdap = val.id;
    },
    getInfoCompanyById() {
      if (this.devEnv) {
        let data = createMockData("org");

        this.modelCompany = Object.assign(this.modelCompany, data);
        this.addressIdFromEkb = data.addressId;
        this.modelCompany.addressId = data.addressId;
        this.modelCompany.addressUaText = data.address;
        this.leasingObjects = data.objectDetailViewList.map(el => ({
          ...el
        }));
        if (data.managerLdap && data.managerFio) {
          this.createSupports(data);
        }
        // mock
        return;
      }

      searchCompanyById(+this.getOrgId)
        .then(res => {
          let data = res.data;
          console.log("searchCompanyById", data);
          this.modelCompany = Object.assign(this.modelCompany, data);
          this.addressIdFromEkb = data.addressId;
          this.modelCompany.addressId = data.addressId;
          this.modelCompany.addressUaText = data.address;
          this.leasingObjects = data.objectDetailViewList.map(el => ({
            ...el
          }));
          if (data.managerLdap && data.managerFio) {
            this.createSupports(data);
          }
        })
        .catch(err => {
          let errorCode = errorHandler(err, "getData");

          this.showSnack("error", [this.$i18n.t(`error.${errorCode}`)]);
          console.log("catch getInfoCompany");
        })
        .finally(() => {
          console.log(this.modelCompany);
        });
    },
    createSupports(data) {
      let arrFio = data.managerFio.split(" ");

      let support = {
        surname: arrFio[0],
        firstName: arrFio[1],
        patronymic: arrFio[2],
        id: data.managerLdap,
        label: `${arrFio[0]} ${arrFio[1]} ${arrFio[2]}`,
        isTouched: false
      };
      this.supports.push(support);
      this.setSupport(support);
    }
  },
  mounted() {
    if (this.pageMode === "edit") {
      this.mode = "company/edit";
      this.getInfoCompanyById();
      this.step = 2;
      this.$emit("event", ["getEsaDefaultSettings", false]);
    }
  }
};
</script>

<style lang="scss">
#addCompanyForm {
  width: 100%;
  .container {
    max-width: 100%;
  }
  [class*="col"] {
    padding: 2px 12px;
  }
  .v-badge {
    width: 100%;
  }
  .v-btn {
    height: 39px;
  }
  .v-alert {
    max-width: 650px;
    font-size: 15px;
    .v-alert__icon {
      height: 32px;
      min-width: 32px;
      margin-left: 10px;
      margin-right: 22px;
      &.v-icon {
        font-size: 24px;
      }
      &::before {
        z-index: 9999;
        color: #fff;
        margin-left: 1px;
        margin-bottom: 1px;
      }
      &::after {
        background-color: #addfb4 !important;
        opacity: 1 !important;
      }
    }
    &--prominent.ERROR {
      .v-alert__icon {
        &.v-icon {
          font-size: 45px;
        }
        &::before {
          color: #d32f2f;
        }
        &::after {
          background-color: #fff !important;
        }
      }
    }
  }
  .v-badge--dot .v-badge__badge {
    width: 12px;
    height: 12px;
    inset: -2px -2px auto auto !important;
  }
  #cancel {
    width: 37%;
  }
  #continue {
    width: 57%;
    &.w-100 {
      width: 100%;
    }
  }
  #enterManually {
    width: 100%;
    min-width: 175px;
  }
  #cancel,
  #continue,
  #enterManually {
    @media (max-width: 600px) {
      width: 100%;
    }
  }
  .detail-wrap {
  }
  .detail-checkbox {
    &.v-input--selection-controls {
      margin-top: 0;
      padding-top: 0;
    }
    .v-input__slot {
      margin: 6px 0;
    }
    .v-label {
      color: rgba(0, 0, 0, 0.87);
    }
  }
  .detail-matrix {
    width: 135px;
    max-width: 200px;
    .v-input__control {
      align-items: flex-end;
    }
    .v-input__slot {
      min-height: 30px;
    }
    input {
      max-height: 30px;
    }
    .v-label {
      top: 5px;
      font-size: 14px;
    }
    &.v-input--is-focused .v-label,
    &.v-input--is-dirty .v-label {
      top: 9px;
    }
  }
}
</style>
